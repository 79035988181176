import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout.js";

export default function fourOhFourPage() {
  return (
    <Layout title="Page not found" description="Page not found">
      <p>
        Sorry! The page you were looking for either doesn't exist or has moved.
      </p>
      <p>
        If there's something you feel is missing, or that you would like to see
        on the website, please <Link to="../contact">get in touch</Link> to
        suggest it.
      </p>
    </Layout>
  );
}
